<template>
  <div>
    <PTabView :activeIndex.sync="activeTab" class="mt-">
      <PTabPanel header="Objectifs/Prévision">
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click.prevent="exportCsv">Export</button>
        </div>
        <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
        <b-table
          show-empty
          class="table-responsive bg-white"
          empty-text="Aucune donnée à afficher"
          :fields="detailsTableFields"
          striped
          ref="table2"
          id="table2"
          :items="previsions"
        >
          <template #head()="data">
            <span>{{ data.label }} </span>
          </template>
          <template #cell(dateDu)="{ item: de }">
            <span class="text-uppercase">{{
              $moment(de.dateDu).format("DD-MM-YYYY")
            }}</span>
          </template>
          <template #cell(dateAu)="{ item: de }">
            <span class="text-uppercase">{{
              $moment(de.dateAu).format("DD-MM-YYYY")
            }}</span>
          </template>
          <template #cell(taux)="{ item: de }">
            <span class="text-uppercase">{{ de.taux }}%</span>
          </template>
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
        </b-table>
      </PTabPanel>
      <PTabPanel header="Visualisation/Bilan">
        <h4>Des graphes ici</h4>
        <PChart type="bar" :data="stackedData" :options="stackedOptions" />
      </PTabPanel>
    </PTabView>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { download_table_as_csv } from "../../../../utils/download-util.js";

export default {
  name: "ObjectifIndicatorList",
  props: {
    targetId: {
      type: Number,
    },
    contratObjectifId: {
      type: Number,
    },
    typeObjectif: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      loading: true,
      previsions: [],
    };
  },
  computed: {
    stackedData() {
      if (this.previsions == null) return {};
      return {
        labels: this.previsions.map((item) => item.typeIndicateur.libelle),
        datasets: [
          // {
          //   type: "bar",
          //   label: "Réalisation",
          //   backgroundColor: "#EC407A",
          //   data: this.previsions.map((item) =>
          //     item.taux < 100 ? 100 - item.taux : 100
          //   ),
          // },
          // {
          //   type: "bar",
          //   label: "Ecart",
          //   backgroundColor: "#AB47BC",
          //   data: this.previsions.map((item) =>
          //     item.taux < 100 ? item.taux : 100
          //   ),
          // },
          {
            type: "bar",
            label: "Réalisation",
            backgroundColor: "rgba(75, 192, 192, 1)",
            data: this.previsions.map((item) => (item.taux < 100 ? item.taux : 100)),
          },
          {
            type: "bar",
            label: "Ecart",
            backgroundColor: "rgba(255, 99, 132, 1)",
            data: this.previsions.map((item) =>
              item.taux < 100 ? 100 - item.taux : 100
            ),
          },
        ],
      };
    },
    stackedOptions() {
      return {
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
    tableFields() {
      return [
        "#",
        // { key: "antenne.intitule", label: "Intitule" },
        { key: "agent.prenom", label: "Prénom" },
        { key: "agent.email", label: "Email" },
        { key: "agent.phone", label: "Phone" },
        { key: "agent.fonction.nom", label: "Fonction" },
        // { key: "participationPlacement.beneficiaire", label: "Prénom" },
        { key: "dateAu", label: "Date Fin" },
        "actions",
      ];
    },
    detailsTableFields() {
      return [
        "#",
        // { key: "agent.nom", label: "Nom" },
        // { key: "agent.prenom", label: "Prénom" },
        { key: "typeIndicateur.libelle", label: "Indicateur" },
        { key: "prevision", label: "Prévision" },
        { key: "realisation", label: "Réalisation" },
        { key: "ecart", label: "Ecart" },
        { key: "taux", label: "Taux Réal." },
        { key: "dateDu", label: "Date Début" },
        // { key: "participationPlacement.beneficiaire", label: "Prénom" },
        { key: "dateAu", label: "Date Fin" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  created() {
    this.loading = true;
    if (this.typeObjectif === "agent") {
      this.fetchContratObjectifParAgents({
        contratObjectifId: this.contratObjectifId,
        agentId: this.targetId,
      })
        .then((data) => {
          this.loading = false;
          this.previsions = data.previsions;
        })
        .catch((error) => {
          this.loading = false;
        });
    } else {
      this.fetchContratObjectifParAntennes({
        contratObjectifId: this.contratObjectifId,
        antenneId: this.targetId,
      })
        .then((data) => {
          this.loading = false;
          this.previsions = data.previsions;
        })
        .catch((error) => {
          this.loading = false;
        });
    }
  },
  methods: {
    ...mapActions({
      fetchContratObjectifParAntennes: "contratObjectif/fetchContratObjectifParAntennes",
      fetchContratObjectifParAgents: "contratObjectif/fetchContratObjectifParAgents",
    }),
    exportCsv() {
      download_table_as_csv("table2", ",", () => {
        this.$toast.success("Téléchargement terminé!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
  },
};
</script>
