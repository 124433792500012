/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <ObjectifAntenneDialog
      v-if="activeObjectifAntenne != null"
      ref="objectifAntenneDialog"
      :contratObjectif="contratObjectif"
      :item="activeObjectifAntenne"
      :title="'Ajout une prévision par antenne '"
      @save="saveObjectifAntenne"
    />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par nom antenne'"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des prévisions par antenne</h4>
        </div>
        <div class="btn-group">
          <!-- <a
            @click.prevent="ajouterObjectifAntenne"
            class="ms-2 style_btn btn btn-warning"
          >
            Ajouter une prévision
          </a> -->
        </div>
        <PMessage
          v-show="errorMessage"
          :severity="'error'"
          @close="errorMessage = null"
          >{{ errorMessage }}</PMessage
        >
        <div class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                id="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>

                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                </template>

                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: cand }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusObjectifAntenneColor(cand.status)"
                    v-if="cand.status"
                    >{{ cand.status }}
                  </PTag>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(dateDu)="{ item: de }">
                  <span class="text-uppercase">{{
                    $moment(de.dateDu).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateAu)="{ item: de }">
                  <span class="text-uppercase">{{
                    $moment(de.dateAu).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #row-details="row">
                  <div class="card bg-white">
                    <ObjectifIndicatorList
                      v-if="contratObjectif != null && contratObjectif.id != null"
                      typeObjectif="antenne"
                      :contratObjectifId="contratObjectif.id"
                      :targetId="row.item.antenne.id"
                    />
                  </div>
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir les ordres de objectifAntennes'"
                      @click="activeDetailChanged(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../../mixins/auth-mixin";
import CAutoComplete from "../../../../components/common/CAutoComplete.vue";
import ObjectifParAgentList from "./ObjectifParAgentList.vue";
import ObjectifAntenneDialog from "../../../../components/espace/common/suivi-evaluation/editor/ObjectifAntenneEditorDialog.vue";
import ObjectifIndicatorList from "./ObjectifIndicatorList.vue";
import { download_table_as_csv } from "../../../../utils/download-util.js";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    contratObjectif: {
      type: Object,
    },
  },
  components: {
    ObjectifAntenneDialog,
    CAutoComplete,
    ObjectifParAgentList,
    ObjectifIndicatorList,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activePriseDeService: {},
      activeCessationService: {},
      activeObjectifAntenne: {},
      participationPlacement: null,
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      activeTab: 0,
      showAdvancedFilter: false,
      activeObjectifAntenneDetail: {},
      nonEvalueesCountLoading: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      placementValidation: {},
      currentOffre: {},
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      activeRecommandation: {
        demandeur: [],
      },
      parAeEnregistres: [],
      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            download_table_as_csv("table", ",", () => {
              this.$toast.success("Téléchargement terminé!", {
                position: "top-right",
                duration: 8000,
              });
            });
            // let params = this.mxGetQueryBuilder();
            // let route = this.$router.resolve({
            //   name: "common.export",
            //   query: {
            //     dataGetter: this.mxDataGetter,
            //     fetcherMethod: "objectifAntenne/exportToExcel",
            //     queryParams: params,
            //     fileName: "Liste des présences au poste.pdf",
            //   },
            // });
            // window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["antenne.intitule"],
      filterOptions: [],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [],
        ordering: "",
      },
      activeCandidature: {},
      activeCandidature: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    stackedData() {
      if (this.activeObjectifAntenneDetail.previsions == null) return {};
      return {
        labels: this.activeObjectifAntenneDetail.previsions.map(
          (item) => item.typeIndicateur.libelle
        ),
        datasets: [
          // {
          //   type: "bar",
          //   label: "Réalisation",
          //   backgroundColor: "#EC407A",
          //   data: this.activeObjectifAntenneDetail.previsions.map(
          //     (item) => item.realisation
          //   ),
          // },
          // {
          //   type: "bar",
          //   label: "Ecart",
          //   backgroundColor: "#AB47BC",
          //   data: this.activeObjectifAntenneDetail.previsions.map((item) => item.ecart),
          // },
          {
            type: "bar",
            label: "Réalisation",
            backgroundColor: "rgba(75, 192, 192, 1)",
            data: this.activeObjectifAntenneDetail.previsions.map((item) =>
              item.taux < 100 ? item.taux : 100
            ),
          },
          {
            type: "bar",
            label: "Ecart",
            backgroundColor: "rgba(255, 99, 132, 1)",
            data: this.activeObjectifAntenneDetail.previsions.map((item) =>
              item.taux < 100 ? 100 - item.taux : 100
            ),
          },
        ],
      };
    },
    stackedOptions() {
      return {
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
    tableFields() {
      return [
        "#",
        { key: "antenne.intitule", label: "Intitulé" },
        { key: "antenne.departement.nom", label: "Département" },
        { key: "antenne.commun", label: "Commune" },
        // { key: "prevision", label: "Prévision" },
        { key: "dateDu", label: "Date Début" },
        { key: "dateAu", label: "Date Fin" },
        "actions",
      ];
    },
    detailsTableFields() {
      return [
        "#",
        // { key: "antenne.nom", label: "Nom" },
        // { key: "antenne.prenom", label: "Prénom" },
        { key: "typeIndicateur.libelle", label: "Indicateur" },
        { key: "prevision", label: "Prévision" },
        { key: "realisation", label: "Réalisation" },
        { key: "ecart", label: "Ecart" },
        { key: "taux", label: "Taux Réal." },
        { key: "dateDu", label: "Date Début" },
        // { key: "participationPlacement.beneficiaire", label: "Prénom" },
        { key: "dateAu", label: "Date Fin" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    // currentOffre() {
    //   const offre = this.mxFilteredItems[0] != null? this.mxFilteredItems[0].offre : {}
    //   return offre
    // }
  },
  methods: {
    ...mapActions({
      createOrUpdateObjectifAntenne: "objectifAntenne/createOrUpdateObjectifAntenne",
    }),
    activeDetailChanged(row, tableData) {
      this.activeObjectifAntenneDetail = { ...row.item };
      this.mxToggleDetails(row, tableData);
    },
    ajouterObjectifAntenne() {
      this.showObjectifAntenneDialog({});
    },
    showObjectifAntenneDialog(objectifAntenne) {
      this.activeObjectifAntenne = {
        contratObjectif: this.contratObjectif,
        ...objectifAntenne,
      };
      this.$refs.objectifAntenneDialog.show();
    },
    saveObjectifAntenne(objectifAntenne) {
      this.createOrUpdateObjectifAntenne(objectifAntenne)
        .then((data) => {
          this.$toast.success("Objectif Antenne sauvegardé avec succès!", {
            position: "top-right",
            duration: 8000,
          });
        })
        .catch((err) => err);
    },
    getStatusObjectifAntenneColor(status) {
      let color = "primary";
      if (status == "CONTROLE") {
        color = "success";
      } else if (status == "CAS_IRREGULIER") {
        color = "danger";
      }

      return color;
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "objectifParAntenne",
        search: this.search,
        fetcherMethod: "objectifParAntenne/fetchObjectifExecutiveAntennes",
        dataGetter: "objectifParAntenne/objectifAntennes",
        paginationGetter: "objectifParAntenne/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          return `${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
